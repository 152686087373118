<template>
    <div>
        <!-- 轮播图 -->
        <el-carousel :interval="5000" arrow="hover" height="380px" class="banner-container" 
            v-if="bannerList.length != 0" :indicator-position="bannerList.length > 1 ? '' : 'none'"
            :class="bannerList.length > 1 ? '' : 'hide_arrow'">
            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                <div class="banner_item" @click="toRoute(item.url)" :style="{backgroundImage:'url('+item.pic+')'}">
                    <div class="banner_tips">详情咨询：13076767004</div>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="cooperation_box">
            <div class="cooperation_title">
                <span>分站代理</span>
            </div>
            <div class="cooperation_pattern">

                <div class="cooperation_pattern_item">
                    <div class="cooperation_pattern_item_num"
                        :style="{backgroundImage:'url('+require('../../assets/imgs/cooperation_icon1.png')+')'}">
                        <span>1</span></div>
                    <div class="cooperation_pattern_item_txt"
                        :style="{backgroundImage:'url('+require('../../assets/imgs/cooperation_icon_nuxt2.png')+')'}">
                        <span>支持自定义logo、品牌、名字和域名</span>
                    </div>
                </div>
                <div class="cooperation_pattern_item">
                    <div class="cooperation_pattern_item_num"
                        :style="{backgroundImage:'url('+require('../../assets/imgs/cooperation_icon1.png')+')'}">
                        <span>2</span></div>
                    <div class="cooperation_pattern_item_txt"
                        :style="{backgroundImage:'url('+require('../../assets/imgs/cooperation_icon_nuxt3.png')+')'}">
                        <span>提供全方位技术支持，你只需要负责运营</span>
                    </div>
                </div>
                <div class="cooperation_pattern_item">
                    <div class="cooperation_pattern_item_num"
                        :style="{backgroundImage:'url('+require('../../assets/imgs/cooperation_icon1.png')+')'}">
                        <span>3</span></div>
                    <div class="cooperation_pattern_item_txt"
                        :style="{backgroundImage:'url('+require('../../assets/imgs/cooperation_icon_nuxt3.png')+')'}">
                        <span>工具自带裂变玩法，低成本获客</span>
                    </div>
                </div>

                <div class="cooperation_pattern_item">
                    <div class="cooperation_pattern_item_num"
                        :style="{backgroundImage:'url('+require('../../assets/imgs/cooperation_icon1.png')+')'}">
                        <span>4</span></div>
                    <div class="cooperation_pattern_item_txt"
                        :style="{backgroundImage:'url('+require('../../assets/imgs/cooperation_icon_nuxt1.png')+')'}">
                        <span>OEM+高额分成+低门槛</span>
                    </div>
                </div>


            </div>
        </div>


        <div class="cooperation_box">
            <div class="cooperation_title">
                <span>代理对象</span>
            </div>

            <div class="cooperation_partner">
                <div class="cooperation_partner_picture">
                    <img :src="require('../../assets/imgs/cooperationPicture.png')" alt="">
                </div>
                <div class="cooperation_ordinances">
                    <div class="cooperation_ordinances_node">
                        <span class="cooperation_ordinances_num">1</span>
                        <span class="cooperation_ordinances_message">电商培训机构</span>
                    </div>

                    <div class="cooperation_ordinances_node">
                        <span class="cooperation_ordinances_num">2</span>
                        <span class="cooperation_ordinances_message">电商自媒体</span>
                    </div>
                    <div class="cooperation_ordinances_node">
                        <span class="cooperation_ordinances_num">3</span>
                        <span class="cooperation_ordinances_message">试用平台</span>
                    </div>
                    <div class="cooperation_ordinances_node">
                        <span class="cooperation_ordinances_num">4</span>
                        <span class="cooperation_ordinances_message">电商服务</span>
                    </div>


                </div>
            </div>


        </div>

        <div class="cooperation_box" style="margin-bottom: 100px;">
            <div class="cooperation_title">
                <span>分站优势</span>
            </div>

            <div class="cooperation_advantage">

                <div class="cooperation_advantage_single">
                    <div class="cooperation_advantage_img"><img :src="require('../../assets/imgs/advantage_icon.png')"
                            alt=""></div>
                    <div class="cooperation_advantage_content">
                        <p class="cooperation_advantage_weight">自定义命名工具箱</p>
                        <p>建立机构/个人品牌竞争力</p>
                    </div>
                </div>
                <div class="cooperation_advantage_single">
                    <div class="cooperation_advantage_img"><img :src="require('../../assets/imgs/advantage_icon.png')"
                            alt=""></div>
                    <div class="cooperation_advantage_content">
                        <p class="cooperation_advantage_weight">工具箱自带裂变</p>
                        <p>低价商户流量获取</p>
                    </div>
                </div>
                <div class="cooperation_advantage_single">
                    <div class="cooperation_advantage_img"><img :src="require('../../assets/imgs/advantage_icon.png')"
                            alt=""></div>
                    <div class="cooperation_advantage_content">
                        <p class="cooperation_advantage_weight">支持独立建站</p>
                        <p>提供全方位技术服务</p>
                    </div>
                </div>
                <div class="cooperation_advantage_single">
                    <div class="cooperation_advantage_img"><img :src="require('../../assets/imgs/advantage_icon.png')"
                            alt=""></div>
                    <div class="cooperation_advantage_content">
                        <p class="cooperation_advantage_weight">提供高额分红比例</p>
                        <p>提升用户价值及商家留存率</p>
                    </div>
                </div>

                <div class="cooperation_advantage_single">
                    <div class="cooperation_advantage_img"><img :src="require('../../assets/imgs/advantage_icon.png')"
                            alt=""></div>
                    <div class="cooperation_advantage_content">
                        <p class="cooperation_advantage_weight">拥有庞大用户群体</p>
                        <p>达成合作建站后可洽谈合作其他业务</p>
                    </div>
                </div>


            </div>
        </div>



    </div>
</template>

<script>
    export default {
        data() {
            return {
                bannerList: [{
                        pic: require('../../assets/imgs/cooperation_banner.jpg'),
                        url: ""
                    },
                ],
            }
        }
    }
</script>

<style lang="less">
    .banner_item {
        min-height: 120px;
        height: 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        background-size: 100% 100%;
        background-position: center;
        position:relative;
    }

    .banner_tips{
        position:absolute;
        bottom:40px;
        left:0;
        right:0px;
        margin:auto;
        text-align:center;
        color:rgb(252,238,10);
        font-size:34px;
        text-shadow:0px 0px 3px #000;
    }

    .cooperation_title {
        text-align: center;
        margin-bottom: 50px;

        span {
            padding-bottom: 14px;
            font-size: 24px;
            border-bottom: 2px solid #409eff;
        }
    }

    .cooperation_box {
        padding: 50px 0;
        margin: 0 auto;
    }

    .cooperation_pattern {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .cooperation_pattern_item {
            display: flex;
            align-items: center;
            padding: 50px 0;

            .cooperation_pattern_item_num {
                display: flex;
                background-repeat: no-repeat;
                background-size: 100%;
                width: 143px;
                height: 98px;
                align-items: center;
                justify-content: center;

                span {
                    font-size: 42px;
                    font-weight: 800;
                    color: #555;
                    line-height: 11px;
                    margin-right: 45px;
                }
            }

            .cooperation_pattern_item_txt {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 448px;
                height: 101px;
                background-repeat: no-repeat;
                background-size: 100%;
                margin-left: -12px;
                margin-top: -52px;

                span {
                    font-size: 20px;
                    font-weight: 500;
                    color: #fff;
                    line-height: 25px;
                }
            }
        }
    }

    .cooperation_partner {
        margin-bottom: 23px;
        display: flex;
        justify-content: flex-start;

        .cooperation_ordinances {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding-left: 44px;
            flex: 1;

            .cooperation_ordinances_node {
                width: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .cooperation_ordinances_num {
                    width: 48px;
                    height: 48px;
                    background: #409eff;
                    opacity: .8;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 34px;
                    font-weight: 800;
                    color: #fff;
                    margin-right: 10px;
                }

                .cooperation_ordinances_message {
                    font-size: 32px;
                    font-weight: 400;
                    color: #444;
                }
            }
        }
    }


    .cooperation_advantage {
        display: flex;
        justify-content: space-between;

        .cooperation_advantage_single {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            .cooperation_advantage_img {
                width: 68px;
                height: 68px;
                margin-bottom: 29px;
            }

            .cooperation_advantage_content {
                font-size: 14px;
                font-weight: 400;
                color: #666;

                .cooperation_advantage_weight {
                    font-size: 18px;
                    font-weight: 700;
                    color: #333;
                    margin-bottom: 17px;
                }
            }
        }
    }

    .hide_arrow{
        .el-carousel__arrow{
            display: none;
        }
    }
</style>